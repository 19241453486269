<template>
  <v-card :color="record.expired ? '#e50000' : 'primary'" dark class="mb-4">
    <v-card-title class="text-subtitle-1 d-flex justify-space-between">
      <div>
        {{ toDate(record.service_datefrom) }} to
        {{ toDate(record.service_dateto) }}
        <div v-if="record.passes_counter != null" class="pt-2">
          <v-chip color="green" text-color="white"
            ><v-icon left small>mdi-ticket-confirmation</v-icon>
            Passes
            <v-avatar
              class="darken-4 ml-1 mr-1"
              :class="
                record.passes_counter == 0
                  ? 'red'
                  : record.passes_counter < 3
                  ? 'yellow'
                  : 'green'
              "
            >
              {{ record.passes_counter }}
            </v-avatar>
            /{{ record.passes }}
          </v-chip>
        </div>
      </div>
      <v-chip v-if="record.expired" outlined color="white">Expired</v-chip>
    </v-card-title>
    <v-card-actions>
      <v-btn
        outlined
        @click="showPdf(record.invoice_id)"><v-icon>mdi-receipt</v-icon> Invoice</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        text
        :disabled="record.count_participations == 0"
        @click="expand = !expand"
      >
        Attendance
        <span v-if="record.count_participations > 0"
          >({{ record.count_participations }})</span
        >
        <v-icon v-if="expand" right>mdi-chevron-up</v-icon>
        <v-icon v-else right>mdi-chevron-down</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider></v-divider>
    <v-expand-transition>
      <my-service-record-attendances
        v-if="expand"
        :service-record-id="record.service_record_id"
        :dcid="dcid"
        @collapse="expand = false"
      ></my-service-record-attendances>
    </v-expand-transition>
  </v-card>
</template>
<script>
// TODO: attendance
import dateTimeMixin from "@/mixins/dateTimeMixin";
import MyServiceRecordAttendances from "@/components/MyServiceRecordAttendances";

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
    dcid: { // dependant_customer_id
      default: null
    }
  },
  data() {
    return {
      expand: false,
    };
  },
  created() {
    this.record.expired = this.isExpired(this.record.service_dateto);
  },
  methods: {
    showPdf(invoice_id) {
      this.$store.dispatch({
        type: "fetchInvoicePdf",
        iid: invoice_id,
        dcid: this.dcid
      }).then(d => this.$router.push({
        name: 'ViewPdf',
        params: {
          src: d.pdf_url
        }
      }));
    }
  },
  components: {
    MyServiceRecordAttendances,
  },
  mixins: [dateTimeMixin],
};
</script>
