<template>
  <div>
    <!-- <Navigation :show-back="true" />
    <v-main>
      <v-container> -->
        <v-row v-if="Object.entries(service).length" justify="center">
          <v-col cols="12" md="4" lg="4">
            <v-card outlined>
              <v-card-title class="headline">{{
                service.service_name
              }}</v-card-title>

              <v-card-subtitle
                >Start Date: {{ toDate(service.startdate) }}</v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="5">
            <v-banner v-if="loaded && !service_records.length" single-line>
              <v-icon slot="icon" color="warning" size="36">
                mdi-alert
              </v-icon>
              No record found

              <template v-slot:actions>
                <v-btn color="primary" text @click="refreshServiceRecords">
                  Refresh
                </v-btn>
              </template>
            </v-banner>
            <my-service-record
              v-for="record in service_records"
              :record="record"
              :key="record.service_record_id"
              :dcid="isDependant? cid : null"
            ></my-service-record>
          </v-col>
        </v-row>
        <v-row v-else-if="loaded" justify="center">
          <v-col md="8">
            <v-banner single-line>
              <v-icon slot="icon" color="warning" size="36">
                mdi-alert
              </v-icon>
              Something is wrong

              <!-- <template v-slot:actions>
                <v-btn color="primary" text>
                  Connecting Settings
                </v-btn>
              </template> -->
            </v-banner>
          </v-col>
        </v-row>
      <!-- </v-container>
    </v-main> -->
  </div>
</template>
<script>
// TODO: Attendance list
// import Navigation from "@/components/Navigation";
import MyServiceRecord from "@/components/MyServiceRecord";
import dateTimeMixin from "@/mixins/dateTimeMixin";

export default {
  props: {
    cid: {
      required: true,
    },
    scid: {
      required: true,
    }
  },
  data() {
    return {
      hasError: false,
      loaded: false,
    };
  },
  async mounted() {
    if (!this.customer.id) {
      await this.$store.dispatch("fetchCustomer");
    }
    if (!this.service.service_id) {
      await this.$store.dispatch("fetchCustomerServices");
    }
    if (!this.service_records || !this.service_records.length) {
      await this.$store.dispatch({
        type: "fetchServiceCustomerRecords", 
        scid: this.scid,
        dcid: this.isDependant ? this.cid : null
      });
    }
    this.loaded = true;
  },
  methods: {
    refreshServiceRecords() {
      this.$store.dispatch({
        type: "fetchServiceCustomerRecords", 
        scid: this.scid,
        dcid: this.isDependant ? this.cid : null
      });
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    service() {
      return this.$store.getters.getCustomerService(this.cid, this.scid);
    },
    service_records() {
      return this.$store.state.service_customer_records[this.scid];
    },
    isDependant() {
      return this.$store.getters.isDependant(this.cid);
    }
  },
  components: {
    // Navigation,
    MyServiceRecord,
  },
  mixins: [dateTimeMixin],
};
</script>
