<template>
  <v-list dense light v-show="expand">
    <template v-for="(attendance, index) in attendances">
      <v-list-item :key="attendance.event_customer_participant_id">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column">
              <span
                v-if="attendance.event_title"
                v-text="attendance.event_title"
                class="font-weight-bold"
              ></span>
              {{ toDayDate(attendance.start_date) }}
              <span
                v-if="attendance.start_time"
                v-text="toTimeRange(attendance.start_time, attendance.end_time)"
              ></span>
              <span
                v-if="attendance.location_name"
                v-text="attendance.location_name"
              ></span>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column align-end">
              <v-chip
                v-if="attendance.status == 1"
                color="green"
                text-color="white"
                small
                >Attended
                <v-icon small right>mdi-checkbox-marked-outline</v-icon></v-chip
              >
              <v-chip
                v-else-if="attendance.status == 0"
                color="red"
                text-color="white"
                small
                >Absent
                <v-icon small right>mdi-close-box-outline</v-icon></v-chip
              >
              <v-chip
                v-if="attendance.passes != null"
                color="blue"
                text-color="white"
                small
              >
                Pass Used: {{ attendance.passes }}
                <v-icon small right>mdi-ticket-confirmation</v-icon>
              </v-chip>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="index"></v-divider>
    </template>
  </v-list>
</template>
<script>
import dateTimeMixin from "@/mixins/dateTimeMixin";

export default {
  props: {
    serviceRecordId: {
      required: true,
    },
    dcid: { // dependant customer id
      default: null
    }
  },
  data() {
    return {
      expand: false,
    };
  },
  mounted() {
    if(!this.attendances){
      this.$store.dispatch({
        type: 'fetchServiceRecordAttendances', 
        srid: this.serviceRecordId,
        dcid: this.dcid
      });
    }
  },
  created() {
    this.expand = true;
  },
  computed: {
    attendances() {
      return this.$store.state.service_record_attendances[this.serviceRecordId];
    },
  },
  watch: {
    expand: function() {
      if (this.expand == false) {
        this.$emit("collapse");
      }
    },
  },
  mixins: [dateTimeMixin]
};
</script>
<style scoped>
.v-chip {
  margin-bottom: 3px;
}
</style>
